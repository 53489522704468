
import BreadCrumbComponent, {
  BreadCrumbLink
} from '@/components/BreadCrumbComponent.vue';
import IconComponent from '@/components/IconComponent.vue';
import DeleteModal from '@/components/Modals/DeleteModalComponent.vue';
import CreateRecruiterModalComponent from '@/components/Modals/Recruiter/CreateRecruiterModalComponent.vue';
import AsyncTableComponent from '@/components/Table/AsyncTableComponent.vue';
import TableSearchService from '@/components/Table/models/TableSearch.service';
import QuickFilterComponent from '@/components/Table/QuickFilterComponent.vue';
import { RecruitingFlightChiefDataProvider } from '@/components/Table/Staff/RecruitingFlightChief/RecruitingFlightChiefDataProvider';
import { recruitingFlightChiefTableConfigBuilder } from '@/components/Table/Staff/RecruitingFlightChief/RecruitingFlightChiefTableConfigBuilder';
import { RecruitingFlightChiefTableData } from '@/components/Table/Staff/RecruitingFlightChief/RecruitingFlightChiefTableData';
import LegacyTableComponent from '@/models/Table/Legacy/LegacyTableComponent.vue';
import TableFilterComponent from '@/components/Table/TableFilterComponent.vue';
import TabsComponent, { TabItem } from '@/components/Table/TabsComponent.vue';
import {
  staffEmailFilter,
  staffFirstNameFilter,
  staffIsArchivedFilter,
  staffLastNameFilter
} from '@/components/Table/Staff/StaffFilters';
import { SearchStaffDto } from '@/models/Dtos/searchStaffDto';
import { Recruiter } from '@/models/Entities/Personas/Recruiter';
import { Staff } from '@/models/Entities/Personas/Staff';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component<FlightChiefRecruiterCollectionPage>({
  components: {
    CreateRecruiterModalComponent,
    TabsComponent,
    LegacyTableComponent,
    IconComponent,
    BreadCrumbComponent,
    DeleteModal,
    AsyncTableComponent,
    TableFilterComponent,
    QuickFilterComponent
  }
})
export default class FlightChiefRecruiterCollectionPage extends Vue {
  @Prop({ default: () => [] })
  readonly recruiters!: Recruiter[];

  readonly links: BreadCrumbLink[] = [
    { label: 'Flight Chief' },
    { label: 'Recruiters' }
  ];

  selectedRecruiters: Recruiter[] = [];

  searchService = new TableSearchService<
    RecruitingFlightChiefTableData,
    Staff,
    never,
    SearchStaffDto
  >(
    this.$store,
    new RecruitingFlightChiefDataProvider(this.$store),
    new SearchStaffDto(),
    { defaultSortProperty: 'identificationInformationLastname' },
    recruitingFlightChiefTableConfigBuilder,
    {
      filters: [
        staffFirstNameFilter,
        staffLastNameFilter,
        staffEmailFilter,
        staffIsArchivedFilter
      ],
      quickFilters: []
    }
  );

  mounted(): void {
    this.searchService.queryData();
  }

  $refs!: {
    createRecruiterModal: CreateRecruiterModalComponent;
    deleteRecruiterModal: DeleteModal;
  };

  get tabs(): TabItem[] {
    return [new TabItem('Recruiters')];
  }

  async createRecruiter(): Promise<void> {
    const createRecruiterModal = this.$refs.createRecruiterModal;
    try {
      (await createRecruiterModal.open()) as Recruiter;
      this.$store.dispatch('snackBarModule/enqueue', {
        message: 'Recruiter created'
      });
      await this.$store.dispatch('recruiterModule/fetchAll');
    } catch (e) {
      console.log('modal rejected');
    }
  }

  handleCellEvent(column: string, recruiter: Recruiter): void {
    if (column === 'archiveRecruiter') {
      this.deleteRecruiter(recruiter);
    }
  }

  async deleteRecruiter(recruiter: Recruiter): Promise<void> {
    const deleteRecruiterModal = this.$refs.deleteRecruiterModal;
    try {
      await deleteRecruiterModal.open(
        recruiter,
        `Are you sure you want to archive ${recruiter.name}?`
      );
      await this.$store.dispatch('recruiterModule/delete', recruiter.id);
      this.$store.dispatch('snackBarModule/enqueue', {
        message: 'Recruiter archived'
      });
      await this.$store.dispatch('recruiterModule/fetchAll');
    } catch (e) {
      console.log('modal rejected');
    }
  }
}
