import { SearchStaffDto } from '@/models/Dtos/searchStaffDto';
import {
  booleanFilterComponentDataBuilder,
  stringFilterComponentDataBuilder
} from '@/models/Table/Table';

/**
 * Filters go into the filter dropdown and usually have inputs to define the filter value
 */
export const staffFirstNameFilter = stringFilterComponentDataBuilder(
  'First Name',
  (dto: SearchStaffDto, value: string) => (dto.firstName = value)
);

export const staffLastNameFilter = stringFilterComponentDataBuilder(
  'Last Name',
  (dto: SearchStaffDto, value: string) => (dto.lastName = value)
);

export const staffEmailFilter = stringFilterComponentDataBuilder(
  'Email',
  (dto: SearchStaffDto, value: string) => (dto.email = value)
);

export const staffIsArchivedFilter = booleanFilterComponentDataBuilder(
  'Is Archived',
  (dto: SearchStaffDto, value: boolean) => (dto.isArchived = value)
);
