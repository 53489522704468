var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "section",
    { staticClass: "p-4 bg-gray-100" },
    [
      _c("bread-crumb-component", { attrs: { links: _vm.links } }),
      _c(
        "div",
        { staticClass: "p-4 mt-8 bg-white border border-gray-200 rounded" },
        [
          _c("tabs-component", {
            attrs: { defaultTabIndex: 0, tabs: _vm.tabs },
          }),
          _c(
            "div",
            { staticClass: "w-full p-4 bg-white" },
            [
              _c(
                "async-table-component",
                _vm._g(
                  _vm._b(
                    {
                      attrs: {
                        isSortable: true,
                        isColumnEditable: true,
                        isFilterable: true,
                        localStorageKey:
                          "flight-chief-recruiter-collection-table",
                        emptyTableMessage: "0 Recruiters",
                      },
                    },
                    "async-table-component",
                    _vm.searchService.tableProps,
                    false
                  ),
                  _vm.searchService.tableEventListeners
                )
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("create-recruiter-modal-component", {
        ref: "createRecruiterModal",
        attrs: { size: "small" },
      }),
      _c("delete-modal", {
        ref: "deleteRecruiterModal",
        attrs: { size: "small", modalTitle: "Delete Recruiter" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }