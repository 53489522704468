import { ModalMounter } from '@/components/ModalMounter';
import { SearchStaffDto } from '@/models/Dtos/searchStaffDto';
import { Staff } from '@/models/Entities/Personas/Staff';
import { ITableConfiguration } from '@/models/Table/Table';
import Routes from '@/router/Routes';
import { RootState } from '@/store/types';
import { Store } from 'vuex';
import LinkElementComponent from '../../Elements/LinkElementComponent.vue';
import { EntityActionButtons } from '../../models/EntityActionButtons';
import { TableConfigBuilder } from '../../models/TableConfigBuilder';
import TableSearchService from '../../models/TableSearch.service';
import { DeleteStaffActionBuilder } from '../EntityActions/DeleteStaffActionBuilder';
import { RecruitingFlightChiefTableData } from './RecruitingFlightChiefTableData';

export const recruitingFlightChiefTableConfigBuilder: TableConfigBuilder<
  RecruitingFlightChiefTableData,
  Staff,
  never,
  SearchStaffDto
> = (
  tableSearchService: TableSearchService<
    RecruitingFlightChiefTableData,
    Staff,
    never,
    SearchStaffDto
  >,
  store: Store<RootState>
): ITableConfiguration<RecruitingFlightChiefTableData, Staff, never> => {
  const tableButtons = new EntityActionButtons<
    RecruitingFlightChiefTableData,
    Staff,
    SearchStaffDto
  >(
    'flight chief',
    store,
    tableSearchService,
    ModalMounter.instance.refs.yesNoModal
  );
  return {
    columnConfigurations: [
      {
        columnTitle: 'Last Name',
        render: {
          componentRef: LinkElementComponent,
          componentProps: (
            rowData: RecruitingFlightChiefTableData
          ): Record<string, unknown> => {
            return {
              displayString: rowData.lastName,
              linkTo: {
                name: Routes.FLIGHT_CHIEF_RECRUITER_DETAILS,
                params: { id: rowData.id }
              }
            };
          }
        },
        sortKey: 'identificationInformationLastname'
      },
      {
        columnTitle: 'First Name',
        render: 'firstName',
        sortKey: 'identificationInformationFirstname'
      },
      {
        columnTitle: 'Email',
        render: 'email',
        sortKey: 'contactInformationEmail'
      },
      {
        columnTitle: 'Is Archived',
        render: 'isArchived'
      },
      tableButtons.buildEntityActionsColumn([new DeleteStaffActionBuilder()])
    ]
  };
};
