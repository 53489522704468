import { Staff } from '@/models/Entities/Personas/Staff';
import { IBaseStaffTableData } from '../BaseStaffTableData';

export class RecruitingFlightChiefTableData implements IBaseStaffTableData {
  entityId: number;
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  isArchived: boolean;

  constructor(staffMember: Staff) {
    this.entityId = staffMember.id;
    this.firstName = staffMember.identificationInformationFirstname;
    this.lastName = staffMember.identificationInformationLastname;
    this.email = staffMember.contactInformationEmail;
    this.isArchived = staffMember.isArchived;
    this.id = staffMember.id;
  }
}
