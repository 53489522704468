var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.show
    ? _c("modal", { attrs: { size: _vm.size }, on: { close: _vm.close } }, [
        !_vm.recruiterCreated
          ? _c("div", { staticClass: "p-4 px-8 text-xs" }, [
              _c("h2", { staticClass: "mb-4 text-xl" }, [
                _vm._v("New recruiter"),
              ]),
              _c("div", { staticClass: "flex flex-col mb-6 md:flex-row" }, [
                _c(
                  "div",
                  { staticClass: "w-full md:w-1/2" },
                  [
                    _c("text-input", {
                      attrs: {
                        "data-cy": "recruiterModalFirstName",
                        errorText: "Please enter a first name",
                        required: true,
                        placeholder: "Enter a Value",
                        label: "First name",
                      },
                      on: {
                        validationState: function ($event) {
                          return _vm.updateFormState("firstName", $event)
                        },
                      },
                      model: {
                        value: _vm.recruiter.identificationInformationFirstname,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.recruiter,
                            "identificationInformationFirstname",
                            $$v
                          )
                        },
                        expression:
                          "recruiter.identificationInformationFirstname",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "w-full mx-0 mt-6 md:w-1/2 md:ml-8 md:my-0" },
                  [
                    _c("text-input", {
                      attrs: {
                        "data-cy": "recruiterModalLastName",
                        placeholder: "Last name",
                        label: "Last name",
                        errorText: "Please enter a last name",
                      },
                      on: {
                        validationState: function ($event) {
                          return _vm.updateFormState("lastName", $event)
                        },
                      },
                      model: {
                        value: _vm.recruiter.identificationInformationLastname,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.recruiter,
                            "identificationInformationLastname",
                            $$v
                          )
                        },
                        expression:
                          "recruiter.identificationInformationLastname",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "w-full mb-6" },
                [
                  _c("text-input", {
                    attrs: {
                      "data-cy": "recruiterModalEmail",
                      placeholder: "Email address",
                      label: "Recruiter's email",
                      regex: _vm.Regex.email,
                      required: true,
                      errorText: "Please enter a valid email.",
                    },
                    on: {
                      validationState: function ($event) {
                        return _vm.updateFormState("email", $event)
                      },
                    },
                    model: {
                      value: _vm.recruiter.contactInformationEmail,
                      callback: function ($$v) {
                        _vm.$set(_vm.recruiter, "contactInformationEmail", $$v)
                      },
                      expression: "recruiter.contactInformationEmail",
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "w-full" }, [
                _c("div", { staticClass: "flex items-center justify-end" }, [
                  _c(
                    "button",
                    {
                      staticClass:
                        "px-4 py-2 mr-4 text-blue-500 border border-blue-500 rounded focus:outline-none focus:shadow-outline",
                      attrs: {
                        "data-cy": "recruiterModalCancelButton",
                        type: "button",
                      },
                      on: { click: _vm.cancel },
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass:
                        "px-4 py-2 text-white rounded focus:outline-none focus:shadow-outline",
                      class: {
                        "opacity-50": !_vm.valid,
                        "bg-gray-500": !_vm.valid,
                        "bg-blue-500": _vm.valid,
                      },
                      attrs: {
                        "data-cy": "recruiterModalSubmitButton",
                        type: "button",
                        disabled: !_vm.valid,
                      },
                      on: { click: _vm.createRecruiter },
                    },
                    [_vm._v(" Generate invite link ")]
                  ),
                ]),
              ]),
            ])
          : _c("div", { staticClass: "p-4 px-8 text-xs" }, [
              _c("div", { staticClass: "text-xl font-semibold" }, [
                _vm._v("Invite link created"),
              ]),
              _c(
                "div",
                {
                  ref: "inviteUrlContainer",
                  staticClass:
                    "p-2 my-4 overflow-auto bg-gray-200 border border-gray-300 rounded",
                },
                [_vm._v(" " + _vm._s(_vm.inviteLinkUrl) + " ")]
              ),
              _c("div", { staticClass: "flex items-center justify-end" }, [
                _c(
                  "button",
                  {
                    staticClass:
                      "px-4 py-2 text-blue-500 border border-blue-500 rounded focus:outline-none focus:shadow-outline",
                    attrs: {
                      "data-cy": "recruiterModalCopyAndCloseButton",
                      type: "button",
                    },
                    on: { click: _vm.copyAndClose },
                  },
                  [_vm._v(" Copy and close ")]
                ),
              ]),
            ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }