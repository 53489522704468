import { SearchStaffDto } from '@/models/Dtos/searchStaffDto';
import { Staff } from '@/models/Entities/Personas/Staff';
import { DeleteEntityActionBuilder } from '../../EntityActions/DeleteEntityAction';
import { EntityActionButtons } from '../../models/EntityActionButtons';
import { IBaseStaffTableData } from '../BaseStaffTableData';

export class DeleteStaffActionBuilder<
  TableDataType extends IBaseStaffTableData
> extends DeleteEntityActionBuilder<TableDataType, Staff, SearchStaffDto> {
  public deleteString: 'delete' | 'archive' = 'delete';
  protected delete = async (
    baseStaffTableData: IBaseStaffTableData,
    entityActionButtons: EntityActionButtons<
      TableDataType,
      Staff,
      SearchStaffDto
    >
  ): Promise<void> => {
    return entityActionButtons.store.dispatch(
      'recruiterModule/delete',
      baseStaffTableData.entityId
    );
  };
}
